export function isValidImgType(file) {
  return /\.(jpe?g|png|pdf)$/i.test(file.name);
}

export function isValidImgSize(fileSize, maxSize) {
  const sizeInKB = fileSize / 1024 / 1024;

  if (sizeInKB <= maxSize) {
    return true;
  } else {
    return false;
  }
}
